<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12">
                <h4>Digital Id</h4>
                <v-card
                        class="mx-auto"
                        max-width="400"
                        outlined
                >
                  <v-card-text class="p-4">
                    <v-list-item three-line>
                      <v-list-item-content>
                        <p class="uppercase name"><strong>{{digital_id.user.full_name}}</strong></p>
                        <p><b>ID
                          No.:</b> {{digital_id.user.setting.midas_id}}
                        </p>
                        <p>{{`${digital_id.user.setting.current_semester_title} ${digital_id.user.setting.program_title}, ${digital_id.user.setting.academic_year}`}}</p>

                        <p>{{digital_id.user.mobile || 'N/A'}}</p>
                        <p class="id-card-address"
                           style="text-transform: capitalize !important;"> {{digital_id.user.setting.id_card_address || 'N/A'}}</p>




                        <p>

                          <b>Validity:</b> {{digital_id.user.student_id_card.bs_expiry_date}}
                        </p>
                        <v-img :src="bar_code"></v-img>


                      </v-list-item-content>
                      <v-list-item-avatar
                              tile
                              size="80"

                      >
                        <v-img :src="digital_id.user.image_path['real']"></v-img>
                      </v-list-item-avatar>
                    </v-list-item>

                  </v-card-text>

                  <v-card-actions>

                  </v-card-actions>
                </v-card>
<!--                <v-card width="56mm" height="88mm" img="https://kist-edu-np.s3.ap-south-1.amazonaws.com/local/uploads/template/e9083f46bc7fae9b87b459256d4285e867df8cff1634841530.jpg">-->
<!--                  <v-card-text>-->
<!--                  <div class="">-->
<!--                    <div class="img-main-background">-->

<!--                    </div>-->
<!--                    <div class="student-id-wrapper">-->
<!--                      <header class="student-id-header">-->
<!--                        <figure class="student-id-pp-image">-->
<!--                          <img :src="digital_id.user.image_path['real']"/>-->
<!--                        </figure>-->
<!--                      </header>-->
<!--                      <main style="margin-left: 10px; margin-top: -10px;">-->

<!--                        <div class="student-id-signature">-->
<!--                          <div class="barcode text-center"  >-->
<!--                            <img :src="bar_code" alt="barcode"  style="contain: strict;" />-->
<!--                            <p>{{digital_id.user.bar_code['barcode_no']}}</p>-->
<!--                          </div>-->
<!--                          <div class="signature">-->
<!--                            <figure>-->
<!--                              <img src="../../../../../../../../home/suraz/Desktop/kist-principal-new-signature.jpeg"/>-->
<!--                            </figure>-->
<!--                            <p class="text-blue text-center">Principal</p>-->
<!--                          </div>-->
<!--                          <div class="clear"></div>-->
<!--                        </div>-->
<!--                      </main>-->

<!--                    </div>-->
<!--                  </div>-->

<!--                  </v-card-text>-->
<!--                </v-card>-->
              </div>
              <!--                        <div class="col-4">-->
              <!--                            <h4>Manage Institution</h4>-->
              <!--                        </div>-->
              <div class="col-md-6 col-lg-6 col-sm-12  mb-3" v-html="digital_id.qr_code">
              </div>
              <div class="col-12" v-if="!digital_id">
                No digital id found
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </v-app>
</template>

<script>
import UserService from "@/core/services/user/UserService";
const user = new userService();

export default {
  name: "digital-id",
  data(){
    return{
      digital_id:false
    }
  },mounted() {
    this.getDigitalId();
  },
  computed: {
    bar_code() {
      return `data:image/png;base64,${this.digital_id.user.bar_code['barcode']}`;
    }
  },
  methods: {
    getDigitalId() {
      user.getDigitalId().then(response=>{
          this.digital_id = response.data
      }).catch(error=>{
        this.$snotify.error("Something went wrong")
      })
    }
  }
}
</script>

<style scoped>
  img, iframe, video, object {
    max-width: 100%;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .text-center {
    text-align: center;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  p {
    font-size: 12px;
  }

  .student-id {
    display: inline-block;
    width: 56mm;
    height: 88mm;
    overflow: hidden;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 5px;
    transform: translateY(80px) translateX(15px);
    margin-bottom: 10px;
    margin-top: 13px;
    position: relative;
    page-break-inside: auto;
  }

  .student-id-wrapper {

  }

  .student-id-header {
    margin-bottom: 5px;
  }

  .student-id-header h1 {
    float: left;
    font-size: 4rem;
    width: 45%;
    margin-top: 13%;
    line-height: 1;
  }

  .student-id-header:after {
    content: '';
    display: table;
    clear: both;
  }

  .student-id-pp-image {
    margin-top: 10px;
    margin-right: 8px;
    float: right;
    width: 56px;
    height: 75px;
    border: 1px solid black;
  }

  .student-id-pp-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .student-id main {
    margin-bottom: 10px;
  }

  .student-id-signature {
    /*margin-top: 5px;*/
  }

  .student-id-signature .barcode {
    width: 65%;
    float: left;
    overflow: hidden;
    margin-top: 10px;
  }

  .student-id-signature .barcode img {
    width: 100%;
    height: 40px;
    object-fit: contain;
    border: 1px solid #000;
  }

  .student-id-signature .barcode p {
    font-size: 10px;
    text-align: center;
  }

  .student-id-signature .signature {
    width: 30%;
    float: right;
    margin-top: -8px;
  }

  .student-id-signature .signature figure {
    height: auto;
    margin-bottom: 3px;
    padding-bottom: 0px;
    border-bottom: 1px solid;
  }

  .student-id-signature .signature figure img {
    margin-top: 18px;
  }

  main {
    padding-right: 10px;
  }

  footer figure {
    float: left;
    width: 40%;
  }

  .kist-logo {
    height: 50px;
  }

  .kist-logo img {
    margin-top: -30px;
  }

  footer article {
    float: right;
    width: 60%;
    padding-left: 10px;
  }

  footer article p {
    font-size: 5px;
  }

  .clear {
    clear: both;
    width: 100%;
  }

  .bg-orange {
    background-color: #eb891b;
    color: #fff;
    padding: 5px;
    margin-top: 10px;
  }

  footer .url-text {
    margin: 0 -10px;
    font-size: 8px;
  }

  .text-blue {
    color: #1a3673;
  }

  .wrapper {
    display: block;
    margin-bottom: 20px;
    margin-top: 15px;
    page-break-inside: auto;
  }

  div {
    margin: 0;
    padding: 0;
  }

  .img-main-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 55mm;
    height: 88mm;
    z-index: -1;
    overflow: hidden;
  }


  .img-main-background img {
    width: 100%;
    height: 100%;
  }

  .id-card-address {
    text-transform: lowercase !important;
  }

  /* Landscape Mode Wrapper CSS Rules */
  .student-id.landscape-mode {
    height: 56mm;
    width: 88mm;
  }

  .student-id.landscape-mode p {
    font-size: 10px;
  }

  .student-id.landscape-mode .student-id-signature .barcode {
    margin-top: 5px;
  }

  .student-id.landscape-mode .img-main-background {
    height: 55mm;
    width: 88mm;
  }

  /*header section gap height here*/
  .student-id.landscape-mode .student-id-header-gap {
    min-height: 60px;
  }

  .student-id-landscape__main {
    padding: 10px;
    margin: 0 !important;
  }

  .landscape-mode__row {
    margin-left: -5px;
    margin-right: -5px;
  }

  .student-id.landscape-mode .student-id-signature .signature figure {
    height: 50px;
  }

  .student-id.landscape-mode .student-id-signature .signature figure img {
    margin-top: 12px;
  }

  .student-id.landscape-mode .student-id-pp-image-wrapper,
  .student-id.landscape-mode .student-id__information {
    float: left;
    padding-left: 5px;
    padding-right: 5px;
  }

  .student-id.landscape-mode .student-id-pp-image {
    margin-right: 0 !important;
    margin-top: 0 !important;
    float: none !important;
    width: 46px;
    height: 65px;
  }

  .float-clearance {
    display: table;
    clear: both;
  }
  p{
    margin: 0!important;
  }
</style>